import Icons from 'features/shared/components/icons';
import { useAnimateHeight } from '@utils/hooks/use-animated-height';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import classNames from 'classnames';
import { Body, H5 } from 'src/features/shared/components/typography';
import styles from './index.module.scss';

interface Props {
  title: string;
  offers: string[];
}

function WebList({ title, offers }: Props) {
  return (
    <div>
      <H5>{title}</H5>
      <div className={styles.webItemsWrapper}>
        {offers.map((offer) => (
          <div key={offer} className={styles.item}>
            <Icons.Checkmark />
            <Body>{offer}</Body>
          </div>
        ))}
      </div>
    </div>
  );
}

function MobileList({ title, offers }: Props) {
  const { containerRef, toggleOpen, isOpen } = useAnimateHeight();

  return (
    <div className={styles.mobileList}>
      <div className={styles.title} onClick={() => toggleOpen()}>
        <H5>{title}</H5>
        {isOpen ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
      </div>
      <div ref={containerRef} className={classNames(styles.mobileItemsWrapper)}>
        {offers.map((offer) => (
          <div key={offer} className={styles.item}>
            <Icons.Checkmark />
            <Body>{offer}</Body>
          </div>
        ))}
      </div>
    </div>
  );
}

interface IOfferListProps extends Props {
  isPanel?: boolean;
}

function OfferList({ isPanel, ...props }: IOfferListProps) {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isMobile = currentBreakpoint === breakpoints.xs.name;

  return isMobile || isPanel ? (
    <MobileList {...props} />
  ) : (
    <WebList {...props} />
  );
}

export default OfferList;
