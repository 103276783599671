import { Image } from 'features/shared/components/image';
import { useIntl } from '@domains/i18n';
import { Anchor } from '@features/club-page/components/club-details/anchor-links/anchor';
import OfferList from '@features/club-page/components/offers-list';
import { IClubAttributeFields } from '@features/pg-funnel/services/contentful/types/generated/contentful';
import { Layout } from '@features/shared/components/layout';
import { Section } from '@features/shared/components/layout/section';

import { HighlightedOfferCard } from './highlighted-offer-card';
import styles from './index.module.scss';
import {
  ClubPageSectionHeading,
  FontColor,
  H5,
} from 'src/features/shared/components/typography';

type Offers = keyof {
  clubOffer?: string[];
  sportsOffer?: string[];
  servicesOffer?: string[];
  groupLessonsOffer?: string[];
};
type PropsKeys = {
  [key in Offers]: string[];
};

interface IProps extends PropsKeys {
  highlightedAttributes: IClubAttributeFields[];
  anchorLinkTitle: string | null;
  isPanel?: boolean;
}

export function ClubOffers({
  highlightedAttributes,
  anchorLinkTitle,
  isPanel,
  ...props
}: IProps) {
  const { formatMessage } = useIntl();
  const messages = {
    title: formatMessage('club-detail.offers.title'),
    subtitle: formatMessage('club-detail.offers.subtitle'),
    clubOffer: formatMessage('club-detail.offers.clubOffer'),
    sportsOffer: formatMessage('club-detail.offers.sportsOffer'),
    servicesOffer: formatMessage('club-detail.offers.servicesOffer'),
    groupLessonsOffer: formatMessage('club-detail.offers.groupLessonsOffer'),
  };

  const Offers = () => {
    const items: Offers[] = [
      'clubOffer',
      'sportsOffer',
      'servicesOffer',
      'groupLessonsOffer',
    ];

    const columnCount = items.map((item) => props[item]).filter(Boolean).length;
    const columns = isPanel ? `xs:12` : `xs:12 sm:${12 / columnCount}`;

    return (
      <>
        {items.map((item) => {
          const offers = props[item];
          if (!offers) {
            return null;
          }
          return (
            <Layout.Col columns={columns} key={item}>
              <OfferList
                title={messages[item]}
                offers={offers}
                isPanel={isPanel}
              />
            </Layout.Col>
          );
        })}
      </>
    );
  };

  return (
    <Section forceMobileView={isPanel}>
      <Anchor title={anchorLinkTitle} />

      <Layout.Container forceMobileView={isPanel}>
        <Layout.Row>
          <Layout.Col columns="xs:12">
            <div className={styles.titleWrapper}>
              <ClubPageSectionHeading forceMobileView={isPanel}>
                {messages.title}
              </ClubPageSectionHeading>
              <Image
                /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                image={{
                  src: '/static/images/sportcity/banners/club/comment-club.svg',
                }}
              />
            </div>
          </Layout.Col>
        </Layout.Row>
        <Layout.Row className={styles.highlightedOffersContainer}>
          {highlightedAttributes &&
            highlightedAttributes.map((attribute) => (
              <HighlightedOfferCard
                key={attribute.name}
                attribute={attribute}
                isPanel={isPanel}
              />
            ))}
        </Layout.Row>
        <Layout.Row>
          <Layout.Col columns="xs:12">
            <H5 color={FontColor.Neutral02}>{messages.subtitle}</H5>
          </Layout.Col>
          <Offers />
        </Layout.Row>
      </Layout.Container>
    </Section>
  );
}
