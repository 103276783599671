import { CLUB_ATTRIBUTE_LARGE_ICON_MAP } from '@features/club-page/constants/club-attribute-icon-map';
import { IClubAttributeFields } from '@features/pg-funnel/services/contentful/types/generated/contentful';
import { Layout } from '@features/shared/components/layout';
import useBreakpoints from '@utils/hooks/use-breakpoints';
import styles from '../index.module.scss';
import {
  FontColor,
  H5,
  Small,
} from 'src/features/shared/components/typography';

export function HighlightedOfferCard({
  attribute,
  isPanel,
}: {
  attribute: IClubAttributeFields;
  isPanel?: boolean;
}) {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const isWeb = currentBreakpoint === breakpoints.lg.name;
  return (
    <Layout.Col columns={isPanel ? 'xs:4' : 'xs:4 sm:2'}>
      <div className={styles.offerCard}>
        {CLUB_ATTRIBUTE_LARGE_ICON_MAP[attribute.icon]}
        <div className={styles.textWrapper}>
          <H5 className={styles.title}>{attribute.title}</H5>
          {isWeb && (
            <Small color={FontColor.Neutral02} className={styles.subtitle}>
              {attribute.subtitle}
            </Small>
          )}
        </div>
      </div>
    </Layout.Col>
  );
}
